<!-- 平台类 -->
<template>
  <section class="platform">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="tabs">
            <p class="title"><span>平台产品系列 </span></p>
            <p class="en">Platform Products</p>
            <p class="btn"><el-button type="text" :class="showClass == 1 ? 'black' : 'grey'" @click="handleTextButtonClick(1)">变电站智慧辅控管理平台</el-button></p>
            <p class="btn"><el-button type="text" :class="showClass == 2 ? 'black' : 'grey'" @click="handleTextButtonClick(2)">变电站远程智能巡视系统</el-button></p>
            <p class="btn"><el-button type="text" :class="showClass == 3 ? 'black' : 'grey'" @click="handleTextButtonClick(3)">智能电子安防监控平台</el-button></p>
            <p class="btn"><el-button type="text" :class="showClass == 4 ? 'black' : 'grey'" @click="handleTextButtonClick(4)">变电站表计数字化远程监视平台</el-button></p>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="main">
            <h1 class="title"><strong>{{ options.title }}</strong></h1>
            <div class="line"></div>
            <!-- <div class="en">{{ options.en }}</div> -->
            <ArticleContent :data="options.content" class="information-content" />
          </div>
        </el-col>
      </el-row>
    </section>
  </section>
</template>

<script>

import imageBanner from '../../components/ImageBanner.vue'
import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, ArticleContent},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/2023/product/导航图.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      options: {title: '', content: []},
      showClass: 1,
      id: 1
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      this.showClass = id
      switch (id) {
        case '1':
          this.options = this.$store.getters.getPlatform1 && JSON.parse(JSON.stringify(this.$store.getters.getPlatform1))
          break
        case '2':
          this.options = this.$store.getters.getPlatform2 && JSON.parse(JSON.stringify(this.$store.getters.getPlatform2))
          break
        case '3':
          this.options = this.$store.getters.getPlatform3 && JSON.parse(JSON.stringify(this.$store.getters.getPlatform3))
          break
        case '4':
          this.options = this.$store.getters.getPlatform4 && JSON.parse(JSON.stringify(this.$store.getters.getPlatform4))
          break
        default:
          this.options = this.$store.getters.getPlatform1 && JSON.parse(JSON.stringify(this.$store.getters.getPlatform1))
          break
      }
    },
    handleTextButtonClick (org) {
      if (org == this.id) return
      this.id = org
      this.showClass = org == 1 ? true : false
      let {...query} = this.$route.query;
      query.id = org
      this.$router.replace({query: {...query}});
    }
  }
}
</script>

<style scoped>
.platform {background-color: #ecf0f5; padding-bottom: 1rem;}
.container {background-color: #ecf0f5;}
.tabs {line-height: 2;}
.tabs .title {font-size: 1.6rem; color: #733c38; border-bottom: 1px solid #ccc; font-weight: 600;}
.tabs .en {margin-bottom: 2rem; text-align: right;}
.tabs .btn {text-align: left;}
.main {background-color: #fff; padding: 2rem 1rem;}
.main .title {text-align: center; font-size: 1.4rem; line-height: 2;}
.main .line {height: 1px; background-color: #e67700; width: 6em; margin: 0 auto 2rem;}
.main .en{text-align: center;margin: 0 auto 2rem;}
.black {color: rgba(0, 0, 0, 1); font-weight: 600;}
.grey {color: rgba(0, 0, 0, .5);;}

</style>